import request from '/src/utils/request';
import otherRequest from '/src/utils/otherRequest';

// 查找省份城市
export function ApiGetCitys(query) {
    return request({
        url: '/common/getCitys',
        method: 'get',
        params: query,
    });
}

// 查找行业
export function ApiGetIndustry(query) {
    return request({
        url: '/common/getIndustry',
        method: 'get',
        params: query,
    });
}

// 查找行业
export function ApiGetIndustryNew(query) {
    return request({
        url: '/common/getIndustryNew',
        method: 'get',
        params: query,
    });
}

// 静态页面获取
export function ApiGetArticle(page) {
    return otherRequest({
        url: '/html/' + page + '.html?v=' + import.meta.env.VITE_VERSION,
        method: 'get',
    });
}

// // 通用 - 文件上传
// export function uploadFileApi() {
//     // 传递两个参数，parent、parentId
// 	return import.meta.env.VITE_BASE_API + '/file/upload';
// }

// // 通用 - 文件下载
// export function ApiFileDownload(query) {
//     // /file/download?parent=finance_project&attachmentId=2988
// 	const tmpUrl = import.meta.env.VITE_BASE_API + '/file/download?' + ['parent=' + query.parent, 'attachmentId=' + query.attachmentId].join('&');

// 	window.open(tmpUrl, '_self');
// }

// // 通用 - 文件删除
// export function ApiDeleteFile(query) {
//     // /file/deleteFile?parent=finance_project&attachmentId=2988
// 	return request({
//         url: '/file/deleteFile',
//         method: 'post',
//         data: query,
//     });
// }

// 测试
export function ApiGetTest() {
    return otherRequest({
        url: '/data/test.json?v=' + import.meta.env.VITE_VERSION,
        method: 'get',
    });
}
